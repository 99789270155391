/* eslint-disable import/no-unresolved */
import React, { useLayoutEffect, useEffect, useContext, createContext, useState } from 'react';
import Meta from 'gatsby-theme-carbon/src/components/Meta';
import Header from 'gatsby-theme-carbon/src/components/Header';
import Switcher from 'gatsby-theme-carbon/src/components/Switcher';
import Footer from 'gatsby-theme-carbon/src/components/Footer';
import Container from 'gatsby-theme-carbon/src/components/Container';
import 'gatsby-theme-carbon/src/styles/index.scss';
import {UserContext, fetchAuthenticatedContent} from '@parallelpublicworks/entitysync';
import parseUser from "../../util/parseUser.js";
import parseAnswers from "../../util/parseAnswers.js";
import parseAnswerAttached from '../../util/parseAnswerAttached.js';
export const NssspContext = createContext({});

const Layout = ({
  children,
  homepage,
  theme,
  titleType,
  pageTitle,
  pageDescription,
  pageKeywords,
  tabs,
  tasks,
  options,
  path
}) => {

  const userContext = useContext(UserContext)
  const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null
  const userData = userState && userState.userData ? userState.userData : {}
  const [user, setUser] = useState({})
  const [answers, setAnswers] = useState(null)
  const [loadingAnswers, setLoadingAnswers] = useState(false)
  const [contextChanged, setContextChanged] = useState(Date.now())
  const [requests, setRequests] = useState({
    loaded: [],
    loading: [],
    received: []
  })
 
  useLayoutEffect(() => {
    const scroll = require('smooth-scroll')('a[href*="#"]', {
      speed: 400,
      durationMin: 250,
      durationMax: 700,
      easing: 'easeInOutCubic',
      clip: true,
      offset: tabs ? 112 : 64,
    });
    return scroll.destroy;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);
 
  useEffect(() => {
    if(userData){
      setUser(parseUser(userData, userState.auth));
      setContextChanged(Date.now())
      if(!loadingAnswers && path.indexOf('reviewer') === -1) {
        setAnswers(parseAnswers(null, tasks.length, userData))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext])

  useEffect(() => {
    if(!loadingAnswers && user?.id){
      fetchUserAnswers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(!loadingAnswers && userData){
      setRequestsForUser(userData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAnswers, userData, contextChanged])

  

  function setRequestsForUser(userData) {
    let loaded = []
    let loading = []
    let received = []
    let lor_data = {}
    if(userData){
      for (const id in userData) {
        if(userData[id].type === 'node--lor_request' && userData[id].relationships?.uid?.data?.id === user?.id){
          lor_data[id] = userData[id]
        }
      }
    }
    if(typeof window !== 'undefined'){
      let local_lor_reqs = localStorage.getItem('nsssp_letters_data')
      if(local_lor_reqs){
        local_lor_reqs = JSON.parse(local_lor_reqs)
        for (const req of local_lor_reqs) {
          if(req.relationships?.uid?.data?.id === user?.id || req?.attributes?.field_request_email === user.attributes?.mail){
            lor_data[req.id] = req
          }
        }
      }
    }
    for (const id in lor_data) {
      if(typeof lor_data[id].id !== 'undefined'){
        loaded.push({ ...lor_data[id] });
        if(typeof lor_data[id].relationships?.field_letter !== 'undefined' && lor_data[id].relationships?.field_letter?.data !== null){
          received.push({ ...lor_data[id] })
        }
      }
    }
    setRequests({
      loaded,
      loading,
      received
    })
  }

  async function fetchUserAnswers(idUser) {
    // console.log('fetching user answers', fetching.current, user?.id && userData);
    if(loadingAnswers) return;

    setLoadingAnswers(true);
    
    if((idUser || user?.id) && userData){

      try {

        let endpoint = `node/answers/?filter[uid.id][value]=${idUser || user.id}`
        endpoint += `&include=field_current_resume,field_letter_interest,field_unofficial_colle,field_unofficial_trans,field_letters_of_recommendation`

        let resp = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, 'GET');

        if(resp) {
          let a_data;
          if(resp.data && Array.isArray(resp.data) && resp.data.length > 0){
            resp = parseAnswerAttached(resp)
            a_data = resp[0]
          }
          await fetchUserRequests(idUser || user?.id, a_data?.attributes?.field_email);
          if(typeof window !== 'undefined' && a_data) {
            localStorage.setItem("nsssp_fetched_answers", JSON.stringify(a_data));
          }
          setAnswers(parseAnswers(a_data, tasks.length, userData));          
          setLoadingAnswers(false);
        } else {
          console.log('Error getting answers false response');
          setLoadingAnswers(false);
        }

      } catch(e) {
        console.log('Error getting answers', e);
        setLoadingAnswers(false);
      }
    }
  }

  async function fetchUserRequests(idUser, email) {
    const endpoint = `node/lor_request/?filter[or-group][group][conjunction]=OR&filter[user-filter][condition][path]=uid.id&filter[user-filter][condition][value]=${idUser || user.id}&filter[user-filter][condition][memberOf]=or-group&filter[mail-filter][condition][path]=field_request_email&filter[mail-filter][condition][value]=${email || user.attributes.mail}&filter[mail-filter][condition][memberOf]=or-group`
    const resp_lor_req = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, 'GET')
    if(resp_lor_req){
      if(resp_lor_req.data && Array.isArray(resp_lor_req.data) && resp_lor_req.data.length > 0){
        if(typeof window !== `undefined`){
          localStorage.setItem("nsssp_letters_data", JSON.stringify(resp_lor_req.data));
        }
      }
    }
  }

  return (
    <NssspContext.Provider value={{user, userData, tasks, options, setUser, loadingAnswers, fetchUserAnswers, contextChanged, requests, answers }}>
      <Meta
        titleType={titleType}
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
      />
      <Header />
      <Switcher />
      <Container homepage={homepage} theme={theme}>
        {children}
        <Footer />
      </Container>
    </NssspContext.Provider>
  );
};

export default Layout;

